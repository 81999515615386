.main ::-webkit-scrollbar-track{
  border-radius: 10px !important;
  background:  var(--bg-color) !important;
}

.main ::-webkit-scrollbar {
  width: 12px !important;
  background-color: var(--fg-color) !important;
  border-radius: 50px !important;
}
.main ::-webkit-scrollbar-thumb {
  background-color: var(--fg-color) !important;
  border-radius: 50px !important;
}