:root {
  --bg-color: #d39b4e;
  --fg-color: #6f492e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  font-family: IBMPlexSansHebrew-Regular;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "IBMPlexSansHebrew-Regular";
  src: url("fonts/OpenSansHebrew-Regular.ttf");
}

@font-face {
  font-family: "IBMPlexSansHebrew-Medium";
  src: url("fonts/OpenSansHebrew-Bold.ttf");
}


@font-face {
  font-family: "IBMPlexSansHebrew-Bold";
  src: url("fonts/OpenSansHebrew-Bold.ttf");
}

.title .ant-modal-title{
  color: transparent !important;
  user-select: none;
}
.ant-modal-close{
 transform: scale(1.5);
}
